const initializeTransactionsList = (
  componentInstance,
  listWrapperRef,
  bufferPxHeight,
  scrollHandlerThrottle
) => {
  console.debug("virtual scroll js is initializing for: ", listWrapperRef, componentInstance);
  const HandleNextPage = "HandleNextPage";
  const HandlePreviousPage = "HandlePreviousPage";
  const HandleRowVisibilityChange = "HandleRowVisibilityChange";

  // helper function for determining if an element is scrollable
  const isScrollable = function(ele) {
    const overflowYStyle = window.getComputedStyle(ele).overflowY;
    return overflowYStyle.indexOf('auto') !== -1;
  };

  // helper function for getting the element that is the scroll box for the given element
  const getScrollableParent = function(ele) {
    return (!ele || ele === document.body)
      ? document.body
      : (isScrollable(ele) ? ele : getScrollableParent(ele.parentNode));
  };

  // calculate and return buffered scroll box height
  const getBufferedScrollBoxHeight = () => scrollBox.clientHeight + (2 * bufferPxHeight);

  // helper function for throttling on scroll event handler
  let throttleTimer;
  const throttle = (callback, time) => {
    if (throttleTimer) return;
    throttleTimer = true;
    setTimeout(() => {
      callback();
      throttleTimer = false;
    }, time);
  };

  // this element is the scrolling overflow-y-hidden parent of the list items
  const scrollBox = getScrollableParent(listWrapperRef)

  // scroll box height
  let bufferedScrollBoxHeight = getBufferedScrollBoxHeight();

  // when the user scrolls the scroll box, do all of these measurements and call a method in the component
  const handleScroll = async () => {
    console.debug("virtual scroll js is handling scroll");

    const dom = getAllMeasurements();

    // if you attempt to get some of these measurements without scrollBox existing then there will be null references thrown
    if (scrollBox && dom.SpacerElementsExist && componentInstance != null) {
      console.debug("virtual scroll js is calculating page position");

      // also send the details of the expanded rows from the dom so that the component can be notified of any changes
      const expandedRowElements = listWrapperRef.querySelectorAll(".expanded-row");
      const expandedRows = Array.from(expandedRowElements).map(e => ({Page: parseInt(e.dataset.page), Row: parseInt(e.dataset.row), Height: Math.trunc(e.offsetHeight)}));

      // if the bottom spacer is visible and if it is not zero height
      if (dom.BottomSpacerOffset < bufferedScrollBoxHeight && dom.BottomSpacerHeight > 0) {

        // tell the component that the user has scrolled to the next page and give it all of the measurements
        await componentInstance.invokeMethodAsync(
          HandleNextPage,
          dom.BottomSpacerOffset,
          Math.trunc(bufferedScrollBoxHeight),
          dom.VisibleHeaderHeight,
          dom.VisibleFooterHeight,
          dom.TopHiddenRowsHeight,
          dom.BottomHiddenRowsHeight,
          dom.VisibleRowsHeight,
          expandedRows);
      }
      // if the top spacer is visible and if it is not zero height
      else if (dom.TopSpacerOffset < bufferedScrollBoxHeight && dom.TopSpacerHeight > 0) {

        // tell the component that the user has scrolled to the previous page and give it all of the measurements
        await componentInstance.invokeMethodAsync(
          HandlePreviousPage,
          dom.TopSpacerOffset,
          Math.trunc(bufferedScrollBoxHeight),
          dom.VisibleHeaderHeight,
          dom.VisibleFooterHeight,
          dom.TopHiddenRowsHeight,
          dom.BottomHiddenRowsHeight,
          dom.VisibleRowsHeight,
          expandedRows);
      }
      else {

        // even if there's no changes with pages, a change in row visibility still needs to be reported for every scroll
        await componentInstance.invokeMethodAsync(
          HandleRowVisibilityChange,
          dom.TopHiddenRowsHeight,
          dom.BottomHiddenRowsHeight,
          dom.VisibleRowsHeight,
          dom.BottomSpacerOffset,
          dom.ScrollTop,
          expandedRows);
      }
    }
  }

  // handle window resize event
  const handleWindowResize = async () => {
    bufferedScrollBoxHeight = getBufferedScrollBoxHeight();

    if (componentInstance != null){
      await componentInstance.invokeMethodAsync("HandleWindowResize", window.innerWidth, window.innerHeight);
    }
  }

  // calculate and return all window/scrollbox DOM measurements
  const getAllMeasurements = () => {
    const topSpacer = listWrapperRef.querySelector(".top-spacer");
    const bottomSpacer = listWrapperRef.querySelector(".bottom-spacer");

    // if you attempt to get some of these measurements without these elements existing then there will be null references thrown
    if (scrollBox && topSpacer && bottomSpacer) {
      console.debug("virtual scroll js is calculating page position");

      const scrollBoxHeight = scrollBox.clientHeight;
      const scrollBoxScrollHeight = scrollBox.scrollHeight;

      const scrollBoxPosition = scrollBox.getBoundingClientRect();
      const topSpacerPosition = topSpacer.getBoundingClientRect();
      const bottomSpacerPosition = bottomSpacer.getBoundingClientRect();
      const listWrapperPosition = listWrapperRef.getBoundingClientRect();

      const scrollBoxOffsetTop = scrollBoxPosition.top - bufferPxHeight;
      const scrollBoxOffsetBottom = scrollBoxPosition.bottom + bufferPxHeight;

      const bottomSpacerOffset = bottomSpacerPosition.top - scrollBoxOffsetTop;
      const bottomSpacerHeight = bottomSpacer.offsetHeight;

      const topSpacerOffset = scrollBoxOffsetBottom - topSpacerPosition.bottom;
      const topSpacerHeight = topSpacer.offsetHeight;

      // these are the heights of the gaps that might show at the top and bottom of the list items
      const visibleHeaderHeight = topSpacerPosition.top - scrollBoxOffsetTop > 0 ? topSpacerPosition.top - scrollBoxOffsetTop : 0;
      const visibleFooterHeight = scrollBoxOffsetBottom - bottomSpacerPosition.bottom > 0 ? scrollBoxOffsetBottom - bottomSpacerPosition.bottom : 0;

      // these are the heights of the list items that appear completely outside the bounds of the scroll box
      const topHiddenRowsHeight = -topSpacerPosition.bottom + scrollBoxPosition.top;
      const bottomHiddenRowsHeight = bottomSpacerPosition.top - scrollBoxPosition.top - scrollBoxHeight;

      const measurements = {
        ScrollBoxHeight: Math.trunc(scrollBoxHeight),
        ScrollBoxScrollHeight: Math.trunc(scrollBoxScrollHeight),
        ScrollBoxHeaderFooterHeight: Math.trunc(scrollBox.scrollTop + (listWrapperRef.getBoundingClientRect().top - scrollBoxPosition.top)),
        ListWrapperTopOffset: Math.trunc(listWrapperPosition.top),
        BottomSpacerOffset: Math.trunc(bottomSpacerOffset),
        BottomSpacerHeight: Math.trunc(bottomSpacerHeight),
        TopSpacerOffset: Math.trunc(topSpacerOffset),
        TopSpacerHeight: Math.trunc(topSpacerHeight),
        VisibleHeaderHeight: Math.trunc(visibleHeaderHeight),
        VisibleFooterHeight: Math.trunc(visibleFooterHeight),
        TopHiddenRowsHeight: Math.trunc(topHiddenRowsHeight),
        BottomHiddenRowsHeight: Math.trunc(bottomHiddenRowsHeight),
        VisibleRowsHeight: Math.trunc(scrollBoxHeight),
        ScrollTop: Math.trunc(scrollBox.scrollTop),
        SpacerElementsExist: true,
      };

      console.debug("virtual scroll js measured complete: ", measurements);

      return measurements;
    }

    const incompleteMeasurements = {
      ScrollBoxHeight: scrollBox?.clientHeight ?? 0,
      ScrollBoxScrollHeight: scrollBox?.scrollHeight ?? 0,
      ScrollBoxHeaderFooterHeight: 0,
      ListWrapperTopOffset: 0,
      BottomSpacerOffset: 0,
      BottomSpacerHeight: 0,
      TopSpacerOffset: 0,
      TopSpacerHeight: 0,
      VisibleHeaderHeight: 0,
      VisibleFooterHeight: 0,
      TopHiddenRowsHeight: 0,
      BottomHiddenRowsHeight: 0,
      VisibleRowsHeight: 0,
      ScrollTop: 0,
      SpacerElementsExist: false,
    };

    console.debug("virtual scroll js measured incomplete: ", incompleteMeasurements);

    return incompleteMeasurements;
  }

  // Initialize scrollBox on scroll event handler
  scrollBox.onscroll = () => throttle(handleScroll, scrollHandlerThrottle);

  // Initialize window resize event handler
  window?.addEventListener("resize", () => throttle(handleWindowResize, scrollHandlerThrottle));

  return {
    // this function is used by the component to get the actual height of an expanded list item
    getRowHeight: (element) => element?.offsetHeight ?? 0,
    // scrolls the scroll box to the specific position by applying top offset
    scrollTo: (top) => scrollBox?.scroll({ top: top, behavior: 'smooth' }),
    // scrolls the scroll box by the specified number of pixels.
    scrollBy: (x, y) => scrollBox?.scrollBy(x, y),
    getWindowWidth: () => window?.innerWidth ?? 0,
    getWindowHeight: () => window?.innerHeight ?? 0,
    dispose: () => {
      componentInstance = null;
      return window?.removeEventListener("resize", handleWindowResize);
    },
    getAllMeasurements
  };
}

window.initializeTransactionsList = initializeTransactionsList;

if (typeof globalThis === "object")
  globalThis.initializeTransactionsList = initializeTransactionsList;
