﻿class TypeformManager {
  constructor() {
    this.instances = new Map();
  }
  create = ({ dotnetObjectReference, typeformId, methodName }) => this.instances.set(typeformId, {
    dotnetObjectReference,
    methodName
  });

  remove = (typeformId) => this.instances.delete(typeformId);

  submit = (formId) => {
    const instance = this.instances.get(formId);

    if (!instance) {
      return;
    }

    const { dotnetObjectReference, methodName } = instance;
    let _ = dotnetObjectReference.invokeMethod(methodName);
  }
}

const typeformManager = new TypeformManager();

window.typeformManager = typeformManager;

window.typeformManagerSubmit = ({ formId, responseId }) =>
  typeformManager.submit(formId);

if (typeof globalThis === "object")
  globalThis.typeformManager = typeformManager;
